import { Container, Row, Col, Button } from "react-bootstrap";

import "./optional-tours.style.scss";

const items = [
    {
        URL: 'https://newsunfiles.blob.core.windows.net/iceworks-images/Images/Images/optional-tours/photo-1582229817369-ba3cf267b608.avif',
        title: 'Tokyo Highlights Tour',
        subtitle: 'Imperial Palace and Asakusa',
        summary: '<b>Duration:</b> 3 Hours<b> Cost: </b> A$130.00<b> Minimum:</b > 20<b> Maximum:</b > Unlimited ',
        content: '<b> The Imperial Palace</b><br />Plaza is the residence for the Imperial Family, located in the centre of Tokyo.Enjoy the Palace Grounds, including the Lotus Moat and Nijuubashi(two bridges that form an entrance to the inner palace grounds)<br /><br /><b>Asakusa</b>        <br />The oldest and most significant example of traditional downtown. It retains the old spirit of the Edo period. The temple precincts bustle with people praying, buying fortunes, shopping or sightseeing. You will also enjoy strolling in Nakamise Arcade which consists of over hundreds of small stores.',
        included: '<b>included</b><ul><li>Exclusive Round trip transport for Iceworks</li ><li>English Speaking Guide</li><li>Bottled Water per person</li><li>Gratuities</li></ul >'
    },
    {
        URL: 'https://newsunfiles.blob.core.windows.net/iceworks-images/Images/Images/optional-tours/Meiji-shrine.jpg',
        title: 'Meiji Shrine & Omotesando',
        subtitle: '',
        summary: '<b>Duration:</b> 2.5 Hours<b> Cost: </b> A$130.00 <b> Minimum:</b > 20 <b> Maximum:</b > Unlimited',
        content: '<b>Meiji Jingu Shrine </b> <br />Shinto Shrine dedicated to Emperor Meiji and his wife, the great- great grandparents of the present emperor.It spreads over 700, 000sq metres covered by a forest of 120,000 trees of 365 species donated by people from all over Japan after the Emperor passed away in 1922.<br/><br/><b>Omotesando</b>    <br />Tokyo is a giant showcase of alluring architecture. Omotesando (near the shrine) is the best place in Tokyo to see the state-of-the-art architecture and shopping.',
        included: '<b>Included</b><ul><li>Exclusive Round trip transport for Iceworks</li><li>English Speaking Guide</li><li>Bottled Water per person</li><li>Gratuities</li></ul >'
    },
    {
        URL: 'https://newsunfiles.blob.core.windows.net/iceworks-images/Images/Images/optional-tours/sword.jpg',
        title: 'Samurai Sword Experience',
        subtitle: '',
        summary: '<b>Duration:</b> 2 Hours<b> Cost: </b> A$357.00<b> Minimum:</b > 20<b> Maximum:</b > 20',
        content: 'School premises for learning spirit exclusively offered to Ice Works.  Learn the discipline of the drawn-sword technique in Swordsmanship, or batto, was vital in times of war to protect oneself and one’s community and in turn to protect and serve the reigning lord of the land.<br /><br />Learning this ancient art can be an opportunity to wides one’s horizon and spirituality.',
        included: '<b>Included</b><ul><li>Exclusive Round trip transport for Iceworks</li><li>English Speaking Guide</li><li>Bottled Water per person</li><li>Gratuities</li><li>Exclusive class</li></ul >'
    },
    {
        URL: 'https://newsunfiles.blob.core.windows.net/iceworks-images/Images/Images/optional-tours/Digital.jpeg',
        title: 'DIGITAL ART MUSEUM',
        subtitle: '',
        summary: '<b>Duration:</b> 3 Hours<b> Cost: </b> A$154.00<b> Minimum:</b > 20<b> Maximum:</b > 40 ',
        content: 'Today you will dive in the latest digital entertainment.<br /><br />Team LabPlanet is a museum where you walk through water, and a garden where you become one with the flowers.There are four massive exhibition spaces and two gardens.<br /><br />By immersing the entire body with other people in these massive body immersive artworks, the boundary between the body and the artwork dissolves, the boundaries between the self, others, and the world become continuous, and we explore a new relationship without boundaries between ourselves and the world.',
        included: '<b>included</b><ul><li>Exclusive Round trip transport for Iceworks</li ><li>English Speaking Guide</li><li>Bottled Water per person</li><li>Gratuities</li><li>Entrances Fees</li></ul >'
    },
    {
        URL: 'https://newsunfiles.blob.core.windows.net/iceworks-images/Images/Images/optional-tours/Fish%20Market.png',
        title: 'TSUKUJI FISH MARKET TOUR WITH JAPANESE COOKING',
        subtitle: '',
        summary: '<b>Duration:</b> 3 Hours<b> Cost: </b> A$357.00<b> Minimum:</b > 20<b> Maximum:</b > 20 ',
        content: 'Explore Tokyo’s traditional and modern fish markets that bustle with life, fresh catches and the salt of the earth types that are integral to its culture.<br /><br />Begin by visiting the Tsukiji outer market to experience the Tsukiji atmosphere as you meander through the small narrow streets replete with an array of seafood restaurants and eateries.<br /><br />In keeping with the theme we then transfer to a nearby venue to indulge in a Japanese lunch, prepared by your very own hands as you receive instruction from an expert.',
        included: '<b>included</b><ul><li>Exclusive Round trip transport for Iceworks</li ><li>English Speaking Guide</li><li>Bottled Water per person</li><li>Gratuities</li><li>Lunch and preparation instruction * Lunch venue is subject to availability and may be revised</li></ul >'
    },
    ];

const OptionalTours = () => {
    const display = items.map(item =>
        <Container className="my-4">
            <Row className=" mb-4">
                <div className="tour-image mb-3 rounded" style={{ backgroundImage: `url(${item.URL})` }}>
                    <div className="tour-image__overlay text-white d-flex align-items-center justify-content-center">
                        <div>
                            <h3 className="display-5">{item.title}</h3>
                            <h4>{item.subtitle}</h4>
                        </div>
                    </div>
                </div>
                <p dangerouslySetInnerHTML={{ __html: item.summary }}></p>
                <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
                <p dangerouslySetInnerHTML={{ __html: item.included }}></p>

            </Row>
        </Container>
    )
    /*return (<><h2 className="text-center text-bold display-4 py-5">Optional Tours</h2>  {display}  </>);*/
    return (<Container className="mt-4">
        <h2 className="text-center text-bold display-4 py-5">Optional Tours</h2>
        <p className="">The eagerly anticipated Optional Tours held at Iceworks returns in 2024. We are busy unlocking the hidden treasures of London, to be explored on Thursday the 5th of September 2024. Stay tuned for an adventure beyond the ordinary with our optional tours to be released in the coming weeks.</p>
        <p className="">Dive into the city's rich tapestry of history, culture, and charm as we take you on a journey through iconic landmarks, secret spots, and off-the-beaten-path wonders with VIP access. Whether you're a history buff, a foodie explorer, or an urban adventurer, there's something for everyone.</p>
        <p className="">Watch out for an email from us listing some unforgettable experiences that will leave you spellbound and craving for more.</p >

        <Row className="bg-primary pt-5 ps-5 align-items-end">
            <Col className="mt-3" lg={5}>
                <p className="px-3 py-4 my-0 fs-5">
                    Reach out to one of our experienced travel managers to book your Optional Tours adventure now!
                </p>
                <Button variant="secondary" className="ms-5" size="lg" href="mailto: info@iceworks24.com.au?subject=Iceworks 24 Stays Enquiry">Enquire Now!</Button>
            </Col>
            <Col className="px-5 d-flex justify-content-end" lg={7}>
                <img height={'150px'} src="https://newsunfiles.blob.core.windows.net/iceworks-24-images/Travel_Footer.png" alt="Banner" />
            </Col>
        </Row>
    </Container>)
};

export default OptionalTours;