import { Accordion, Container, Row } from "react-bootstrap";


import "./faq.style.scss";
import { Link } from "react-router-dom";

const Registrations = [
    {
    id: 0,
    header: 'How do I register?',
        body: `Click on the following link to register today for <b>members</b>: <a target='_blank' href="https://bxagency.eventsair.com/iceworks-24/members">Iceworks 24 REGISTRATION PAGE FOR MEMBERS </a><br /><br />Click on the following link to register today for <b>suppliers</b>: <a target='_blank' href="https://bxagency.eventsair.com/iceworks-24/suppliers">Iceworks 24 REGISTRATION PAGE FOR SUPPLIERS</a>`
    },
    {
        id: 1,
        header: 'When does the registration close?',
        body: `Friday, 31th May 2024, unless all spots have been filled prior. The conference has been capped for Iceworks 24 London.`
    },
    {
        id: 2,
        header: 'Can I book my own flights and get reimbursed',
        body: `No, all travel must be booked through the Iceworks 24 events team. `
    },
    {
        id: 3,
        header: 'Does my registration include travel insurance?',
        body: `Registration does not include travel insurance of any kind. It is recommended that attendees take out their own general travel insurance. No responsibility is taken by New Sunrise or the conference organisers for any person(s) not holding valid insurance.<br /><br />We strongly recommend that all delegates attending Iceworks 24 in London have travel insurance for the duration of your trip. All delegates, family members and travelling companions should have adequate travel insurance to cover cancellation, luggage or other unforeseen incidents where you may require financial support and/or assistance. <br /><br />Please find relevant links below to obtain a quote for travel insurance:<br />
          <ul><li><a href="https://www.nibtravelinsurance.com.au/">NIB</a></li><li><a href="http://www.covermore.com.au/">Covermore</a></li></ul>Travellers with pre-existing medical conditions or questions relating to travel insurance should speak to their preferred insurance company before completing their policy online. Travel Beyond can only provide general information about travel insurance & cannot provide you advice or recommendations about travel insurance.`
    },
]
const Eligibilitys = [
    {
        id: 0,
        header: 'How do I know how many smiles I have?',
        body: `You have received an email from New Sunrise ‘REGISTRATIONS ARE NOW OPEN for Iceworks 24’ which includes the total smiles you achieved for the 2023 – 2024 incentive period. <br/>
              <br/> For more information on this please contact the New Sunrise head office on (02) 9815 7800.`
    },
    {
        id: 1,
        header: 'Can I combine smiles from multiple sites to increase my eligibility?',
        body: `No, SMILES may only be used from the one location for each registration.`
    }
    
]
const AdditionalGuestss = [
    {
        id: 0,
        header: 'Can I bring my partner who is a non-member?',
        body: `Yes, there is a partner package available for all non-members, including spouses or accompanying guests.`
    },
    {
        id: 1,
        header: 'What does the partner package include?',
        body: `The partner package includes flights, accommodation, breakfast plus an invitation to attend all official functions and group activities including the Snowball Gala Dinner.`
    },
    {
        id: 2,
        header: `What happens if I don't want to buy a partner package but want to make my own arrangements for my spouse or accompanying guest?`,
        body: ` For safety all persons attending Iceworks 24 will need to be a registered delegate. If your partner attends the conference without prior knowledge and payment, the cost will be passed on at the conclusion of the Event.`
    },
    {
        id: 3,
        header: 'Can I bring my children?',
        body: `Yes, attendees are welcome to bring their children to Iceworks 24. Simply register each child through the registration portal, which will feature all associated registration fees and inclusions.<br/><br/>Child-minding options will also be made available closer to the event (payable at your own expense).`
    },
]
const Flightss = [
    {
        id: 0,
        header: `Why can't I choose which airline I travel on?`,
        body: `New Sunrise have secured enough seats for the group to travel to and from London with multiple airlines on the selected group dates through a pre-purchased group booking. Therefore, all subsidised and partially subsidised tickets include group seats held.`
    },
    {
        id: 1,
        header: 'Will I be able to collect Frequent Flyer points?',
        body: `All frequent flyer points are earned on all group flights only for Iceworks 24. You must be a member of the selected airlines’ frequent flyer programs to earn points. Membership and points are subject to the terms and conditions of the frequent flyer service program.<br /><br />It is your responsibility to ensure you accurately advise of your Frequent Flyer Membership number when you register.`
    },
    {
        id: 2,
        header: 'How do I upgrade my flight with points?',
        body: ` Should you wish to upgrade your travel using points, you must first speak with Iceworks 24 team and finalise your flight booking and have your ticket issued. Only once your airline ticket is issued can you then speak to the airline directly to register for an upgrade using points.<br /><br />Please note upgrades will only be confirmed to you inside 24 hours from travel. The only way to guarantee travel in Business class is to pay the upgrade cost when your booking is made. To do this you will need to speak with the Iceworks 24 team who will provide you the best available airfare supplement at time of booking. `
    },
   
]

const CandTs = [
    {
        id: 0,
        header: 'What happens if I need to cancel my registration?',
        body: `New Sunrise contracts various suppliers, such as hotels, airlines & restaurants, to secure availability for our guests taking part in the Iceworks program each year.  Suppliers impose strict terms & conditions within these contracts that will only allow for a reduction or cancellation of our contract up to a certain period prior to the event date.</br>
                We understand that sometimes cancellations occur, however due to contractual obligations with our third-party suppliers in London, any cancellations are subject to strict terms and conditions, therefore cancellation fees will apply; </br>
            <ul>
                <li>Cancellation prior to 15 June 2024 – 30% of your registration fee will apply</li>
                <li>Cancellation after 15 June 2024  – 50% of your registration fee will apply</li>
                <li>Cancellation after 15 July 2024 - 100% of the  registration fee will apply</li>
            </ul>All cancellations must be given in writing via email to BX Agency Pty Ltd. Cancellations cannot be offset with conference credits and any applicable charges will be debited from your trading account.`
    },
    {
        id: 1,
        header: 'I can no longer attend, can I transfer my registration to someone else?',
        body: ` Yes, you are able to transfer your registration. However, any cancellations or change fees that are incurred will be passed on.`
    },
    {
        id: 2,
        header: 'I have booked an optional tour and no longer can attend',
        body: `The full fee for your tour will be transferred to the independent tour operator. If you need to cancel your tour booking within 30 days of the tour date, any refunds will be subject to the tour operators’ cancellation policy. `
    },
    
]


const TravelExtensionss = [
    {
        id: 0,
        header: 'Can I extend my travel pre or post event?',
        body: `Yes, the Iceworks 24 team at Events Beyond will be able to assist with your extension travel options upon request. The airlines do charge a deviation fee from the Group dates along with any fare difference. Please advise your requirements when registering and the team will contact you directly to discuss your options, provide a quotation and confirm the booking once accepted by you.<br /><br />
            <b>NOTE:</b> Transfers to/from airport will <b>NOT</b> be provided on days outside of the group dates, please contact the Iceworks 24 events team via email <a target="_blank" href="mailto:info@iceworks24.com.au">info@iceworks24.com.au</a> if you would like a private transfer organised on your behalf.`
    },
    {
        id: 1,
        header: 'Are there any extension travel options available?',
        body: `Yes, the Iceworks 24 team have developed several extensions travel itineraries to the following destinations. Click Here  For more information on these exciting programs please <Link to="/ExtendYourStay">click here</Link>, or email the Iceworks 24 team at  <a target="_blank" href="mailto:info@iceworks24.com.au">info@iceworks24.com.au</a>`
    },
    {
        id: 2,
        header: 'How do I book my extension package?',
        body: ` To book an extension travel package simply select your preferred package within the travel section of the registration portal. Events Beyond will contact you directly to finalise your arrangements. `
    },
    {
        id: 3,
        header: 'When will I have to pay for my extension package?',
        body: `  All extension travel must be paid directly to the Iceworks 24 team prior to ticketing your booking. Once payment is received, the events team will finalise the booking and send you all travel confirmations.`
    },
    {
        id: 4,
        header: 'I have booked an extension package, however my plans have changed',
        body: `  Please contact the Iceworks 24 team directly to discuss your options. Cancellation fees may apply.`
    },
]

const FAQ = () => {
    const Registration = Registrations.map(Registration =>                       
            <Accordion.Item eventKey={Registration.id}>
            <Accordion.Header><b>{Registration.header}</b></Accordion.Header>
            <Accordion.Body dangerouslySetInnerHTML={{ __html: Registration.body}}></Accordion.Body>
            </Accordion.Item>            
    )
    const Eligibility = Eligibilitys.map(Eligibility =>
        <Accordion.Item eventKey={Eligibility.id}>
            <Accordion.Header><b>{Eligibility.header}</b></Accordion.Header>
            <Accordion.Body dangerouslySetInnerHTML={{ __html: Eligibility.body }}></Accordion.Body>
        </Accordion.Item>
    )
    const AdditionalGuests = AdditionalGuestss.map(AdditionalGuests =>
        <Accordion.Item eventKey={AdditionalGuests.id}>
            <Accordion.Header><b>{AdditionalGuests.header}</b></Accordion.Header>
            <Accordion.Body dangerouslySetInnerHTML={{ __html: AdditionalGuests.body }}></Accordion.Body>
        </Accordion.Item>
    )
    const Flights = Flightss.map(Flights =>
        <Accordion.Item eventKey={Flights.id}>
            <Accordion.Header><b>{Flights.header}</b></Accordion.Header>
            <Accordion.Body dangerouslySetInnerHTML={{ __html: Flights.body }}></Accordion.Body>
        </Accordion.Item>
    )
    const CandT = CandTs.map(CandT =>
        <Accordion.Item eventKey={CandT.id}>
            <Accordion.Header><b>{CandT.header}</b></Accordion.Header>
            <Accordion.Body dangerouslySetInnerHTML={{ __html: CandT.body }}></Accordion.Body>
        </Accordion.Item>
    )
    const TravelExtensions = TravelExtensionss.map(TravelExtensions =>
        <Accordion.Item eventKey={TravelExtensions.id}>
            <Accordion.Header><b>{TravelExtensions.header}</b></Accordion.Header>
            <Accordion.Body dangerouslySetInnerHTML={{ __html: TravelExtensions.body }}></Accordion.Body>
        </Accordion.Item>
    )

    return(
        <>
            <Container className="my-4">
                <h2 className="text-center text-bold display-4 py-5">FAQ</h2>
                <Row>
                    <h3 className="display-5 text-bold">Got a question?</h3>
                    <p>We've compiled this handy list to help answer those burning questions. If the answer isn't below please reach out to the <a href="mailto:info@iceworks24.com.au">Iceworks 24 Team</a></p>
                </Row>
                <Row className="mb-5">
                <Accordion> 
                <h3>Registration</h3>
                        {Registration}<br /><br />
                    </Accordion>
                <Accordion>
                <h3>Eligibility</h3>
                        {Eligibility}<br /><br />
                    </Accordion><br />
                <Accordion>
                <h3>Additional Guests</h3>
                        {AdditionalGuests}<br /><br />
                    </Accordion><br />
                <Accordion>
                <h3>Flights</h3>
                        {Flights}<br /><br />
                    </Accordion><br />
                <Accordion>
                <h3>Cancellations / Transfers</h3>
                        {CandT}<br /><br />
                    </Accordion><br />
                <Accordion>
                <h3>Travel Extensions</h3>
                        {TravelExtensions}<br /><br />
                </Accordion>
                </Row>
            </Container>
        </>
    )
}
export default FAQ;