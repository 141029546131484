import { Container, Row, Col, Button } from "react-bootstrap";


const contents = {
    a: {
        URL: 'https://newsunfiles.blob.core.windows.net/iceworks-24-images/tenerife_banner.jpg',
        title: 'CRUISE: 12 NIGHT DISCOVER THE CANARIES',
        subtitle: '',
        content1: `<ul>
                        <li>CABIN: OCEANVIEW FROM $5,566.66 AUD FOR 2 ADULTS</li>
                        <li>CRUISE LINE: ROYAL CARIBBEAN</li>
                        <li>DEPARTS 8 – 20 SEPT 2024</li>
                        <li>CRUISE SHIP: Anthem of the Seas</li>
                        <li>ROUNDTRIP FROM: Southampton, England</li>
                    </ul>`,
        content2: `<b><u>Visiting </u></b><br />
                        <ul>
                          <li>Southampton, England</li>
                          <li>La Coruna, Spain</li>
                          <li>Madeira (Funchal), Portugal</li>
                          <li>Lanzarote, Canary Islands</li>
                          <li>Gran Canaria, Canary Islands</li>
                          <li>Tenerife, Canary Islands</li>
                          <li>Lisbon, Portugal</li>
                          <li>Southampton, England</li>
                        </ul>`
    },
    b: {
        URL: 'https://newsunfiles.blob.core.windows.net/iceworks-24-images/santorini_banner.jpg',
        title: 'CRUISE: 7 NIGHT GREEK ISLE CRUISE',
        subtitle: '',
        content1: `<ul>
                        <li>CABIN: OCEANVIEW FROM $6,980 AUD FOR 2 ADULTS</li>
                        <li>CRUISE LINE: ROYAL CARIBBEAN</li>
                        <li>DEPARTS : 07 – 14 SEPT</li>
                        <li>CRUISE SHIP: Explorer of the Seas</li>
                        <li>ROUNDTRIP FROM: Ravenna, Italy</li>
                    </ul>`,
        content2: `<b><u>Visiting</u></b><br />
                       <ul>
                          <li>Ravenna, Italy</li>
                          <li>Santorini, Greece</li>
                          <li>Mykonos, Greece</li>
                          <li>Athens (Piraeus), Greece</li>
                          <li>Olympia (Katakolon), Greece</li>
                          <li>Ravenna, Italy</li>
                        </ul>`
    },
   
};

function Display({ name }) {
    const content = contents[name];
    return(
        <Container className="mt-4">
        <Row className="mb-4">
        <div className="tour-image mb-3 rounded" style={{ backgroundImage: `url(${content.URL})` }}>
            <div className="tour-image__overlay text-white d-flex align-items-center justify-content-center">
                <div className="text-center">
                            <h3 className="display-5">{content.title}</h3>
                            <h4 dangerouslySetInnerHTML={{ __html: content.subtitle }}></h4>
                </div>
            </div>
        </div>
        <p dangerouslySetInnerHTML={{ __html: content.content1 }}></p>
        <br /><br />
        <p dangerouslySetInnerHTML={{ __html: content.content2 }}></p>               
            </Row>
        </Container>        
    );
}

const ExtendStays = () => {
    return(
        <Container className="my-4">
            <h2 className="text-center text-bold display-4 py-5">Extended Stays & Tours</h2>
            <p>Embark on a journey of luxury and adventure with our captivating European cruises! Set sail to exotic destinations around Europe, from the sun-kissed shores of the Mediterranean to the sparkling waters of the Greek Islands. Indulge in unparalleled comfort, world-class dining, and thrilling onboard activities for the whole family. Whether you seek relaxation on pristine beaches or cultural immersion in vibrant cities, our cruises offer the perfect blend of exploration and relaxation. Unleash your wanderlust and create memories to last a lifetime. Explore our cruise offerings now and chart your course to unforgettable experiences at sea!</p> 
                <Display name="a" />
            <Display name="b" />
            <Row className="bg-primary ps-5 align-items-end">
                <Col className="mt-3" lg={5}>
                    <p className="px-4 py-4 my-0 fs-5">
                        Reach out to one of our experienced travel managers to book your Extended Stay adventure now!
                    </p>
                    <Button variant="secondary" className="ms-5" size="lg" href="mailto: info@iceworks24.com.au?subject=Iceworks 24 Stays Enquiry">Enquire Now!</Button>
                </Col>
                <Col className="px-5 d-flex justify-content-end" lg={7}>
                    <img height={'150px'} src="https://newsunfiles.blob.core.windows.net/iceworks-24-images/Travel_Footer.png" alt="Banner" />
                </Col>
            </Row>
        </Container>
    )
}
            
export default ExtendStays;