import { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { LinkContainer } from 'react-router-bootstrap'
import {Link, useLocation} from 'react-router-dom';

import "./navigation.style.scss";

const Navigation = () => {

    const location = useLocation();
    const {pathname} = location;


    return (
        <>
        <Navbar className="navbar px-3" collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Container fluid className="px-4">
                <Link style={{width: "85px"}} to="/">
                        <img className="logo-small" src="https://newsunfiles.blob.core.windows.net/iceworks-24-images/Iceworks_Logo.png" alt="Iceworks Logo"/>
                </Link>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">

             

                <Nav className="mx-auto">
                    <LinkContainer to="/">
                        <Nav.Link>Home</Nav.Link>
                    </LinkContainer>
                    <NavDropdown title="About" id="collasible-nav-dropdown">
                        <LinkContainer to="about">
                            <NavDropdown.Item >Explore London</NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to="about/hotel">
                            <NavDropdown.Item >Hotel Information</NavDropdown.Item>    
                        </LinkContainer>
                        <LinkContainer to="about/flights">
                            <NavDropdown.Item >Flights</NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to="about/important-information">
                            <NavDropdown.Item>Important Information</NavDropdown.Item>
                        </LinkContainer>
                    </NavDropdown>

                        <LinkContainer to="optional-tours">
                            <Nav.Link>Optional Tours</Nav.Link>
                        </LinkContainer>


                        <NavDropdown title="Stays" id="collasible-nav-dropdown">
                            <LinkContainer to="stays/mini-stays">
                                <NavDropdown.Item >Mini Stays</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="stays/bespoke-stays">
                                <NavDropdown.Item >Bespoke Stays</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to="stays/extended-stays">
                                <NavDropdown.Item >Extended Stays & Tours</NavDropdown.Item>
                            </LinkContainer>                                 
                        </NavDropdown>

                    <LinkContainer to="faq">
                        <Nav.Link>FAQ</Nav.Link>
                    </LinkContainer>
                        </Nav>                       
                        <Nav>
                    <NavDropdown title="Register Now" id="collasible-nav-dropdown">
                                <a className='text-dark dropdown-item' target='_blank' href='https://bxagency.eventsair.com/iceworks-24/members'>Members</a>
                                <a className='text-dark  dropdown-item' target='_blank' href='https://bxagency.eventsair.com/iceworks-24/suppliers'>Suppliers</a>
                    </NavDropdown>
                </Nav> 
                </Navbar.Collapse>
            </Container>
        </Navbar>
        </>
    )
}

export default Navigation;