import { useEffect, useReducer } from 'react';
import { Accordion, Col, Container, Row, Spinner } from 'react-bootstrap';
import { getFlightspageContentAsync } from '../../services/strapi';
import contentReducer, { INITIAL_STATE } from '../../state/state';
import ReactMarkDown from 'react-markdown';

import "./flights.style.scss"; 
import { Fade } from 'react-awesome-reveal';

const Flights = () => {

    let isflightDetails = true;

    const sydneyDeparting = [
        {
            Airline: 'Emirates',
            FlightNo: 'EK413',
            Date: 'Sunday 1st September 2024',
            DepartureCity: 'Sydney',
            ArrivalCity: 'Dubai',
            DepartureTime: '21:10',
            ArrivalTime: '05:40',
            Duration: '14h 30m',
            ExtraInfo: 'Arrives next day'
        },
        {
            Airline: 'Emirates',
            FlightNo: 'EK15',
            Date: 'Monday 2nd September 2024',
            DepartureCity: 'Dubai',
            ArrivalCity: 'London Gatwick',
            DepartureTime: '08:00',
            ArrivalTime: '12:35',
            Duration: '07h 35m',
            ExtraInfo: ''
        },
    ]

    const sydneyReturn = [
        {
            Airline: 'Emirates',
            FlightNo: 'EK16',
            Date: 'Friday 6th September 2024',
            DepartureCity: 'London Gatwick',
            ArrivalCity: 'Dubai',
            DepartureTime: '14:35',
            ArrivalTime: '00:40',
            Duration: '07h 05m',
            ExtraInfo: 'Arrives next day'
        },
        {
            Airline: 'Emirates',
            FlightNo: 'EK414',
            Date: 'Saturday 7th September 2024',
            DepartureCity: 'Dubai',
            ArrivalCity: 'Sydney',
            DepartureTime: '02:15',
            ArrivalTime: '22:05',
            Duration: '13h 50m',
            ExtraInfo: ''
        }
    ];

    const brisbaneDeparting = [
        {
            Airline: 'Qantas',
            FlightNo: 'QF51',
            Date: 'Sunday 1st September 2024',
            DepartureCity: 'Brisbane',
            ArrivalCity: 'Singapore',
            DepartureTime: '11:05',
            ArrivalTime: '17:20',
            Duration: '08h 15m',
            ExtraInfo: ''
        },
        {
            Airline: 'Qantas',
            FlightNo: 'QF1',
            Date: 'Monday 2nd September 2024',
            DepartureCity: 'Singapore',
            ArrivalCity: 'London Heathrow',
            DepartureTime: '23:15',
            ArrivalTime: '06:35',
            Duration: '14h 20m',
            ExtraInfo: 'Arrives next day'
        },
        {
            Airline: 'Emirates',
            FlightNo: 'EK435',
            Date: 'Sunday 1st September 2024',
            DepartureCity: 'Brisbane',
            ArrivalCity: 'Dubai',
            DepartureTime: '21:00',
            ArrivalTime: '05:10',
            Duration: '14h 10m',
            ExtraInfo: 'Arrives next day'
        },
        {
            Airline: 'Emirates',
            FlightNo: 'EK15',
            Date: 'Monday 2nd September 2024',
            DepartureCity: 'Dubai',
            ArrivalCity: 'London Gatwick',
            DepartureTime: '08:00',
            ArrivalTime: '12:35',
            Duration: '07h 35m',
            ExtraInfo: ''
        },
    ]
    const brisbaneReturn = [
        {
            Airline: 'Qantas',
            FlightNo: 'QF2',
            Date: 'Friday 6th September 2024',
            DepartureCity: 'London Heathrow',
            ArrivalCity: 'Singapore',
            DepartureTime: '20:45',
            ArrivalTime: '17:25',
            Duration: '13h 40m',
            ExtraInfo: 'Arrives next day'
        },
        {
            Airline: 'Qantas',
            FlightNo: 'QF52',
            Date: 'Sunday 8th September 2024',
            DepartureCity: 'Singapore',
            ArrivalCity: 'Brisbane',
            DepartureTime: '20:30',
            ArrivalTime: '06:05',
            Duration: '07h 35m',
            ExtraInfo: 'Arrives next day'
        },
        
        {
            Airline: 'Emirates',
            FlightNo: 'EK16',
            Date: 'Friday 6th September 2024',
            DepartureCity: 'London Gatwick',
            ArrivalCity: 'Dubai',
            DepartureTime: '14:35',
            ArrivalTime: '00:40',
            Duration: '07h 05m',
            ExtraInfo: 'Arrives next day'
        },
        {
            Airline: 'Emirates',
            FlightNo: 'EK430',
            Date: 'Saturday 7th September 2024',
            DepartureCity: 'Dubai',
            ArrivalCity: 'Brisbane',
            DepartureTime: '02:30',
            ArrivalTime: '22:20',
            Duration: '13h 50m',
            ExtraInfo: ''
        },
        ]
    const hobartDeparting = [
        {
            Airline: 'Qantas',
            FlightNo: 'QF1004',
            Date: 'Sunday 1st September 2024',
            DepartureCity: 'Hobart',
            ArrivalCity: 'Melbourne (Tullamarine)',
            DepartureTime: '12:40',
            ArrivalTime: '14:00',
            Duration: '01h 20m',
            ExtraInfo: ''
        },
        {
            Airline: 'Qantas',
            FlightNo: 'QF37',
            Date: 'Sunday 1st September 2024',
            DepartureCity: 'Melbourne (Tullamarine)',
            ArrivalCity: 'Singapore',
            DepartureTime: '15:50',
            ArrivalTime: '22:05',
            Duration: '08h 15m',
            ExtraInfo: ''
        },
        {
            Airline: 'Qantas',
            FlightNo: 'QF1',
            Date: 'Monday 2nd September 2024',
            DepartureCity: 'Singapore',
            ArrivalCity: 'London Heathrow',
            DepartureTime: '23:15',
            ArrivalTime: '06:35',
            Duration: '14h 20m',
            ExtraInfo: 'Arrives next day'
        },]
    const hobartReturn = [
        {
            Airline: 'Qantas',
            FlightNo: 'QF2',
            Date: 'Friday 6th September 2024',
            DepartureCity: 'London Heathrow',
            ArrivalCity: 'Singapore',
            DepartureTime: '20:45',
            ArrivalTime: '17:25',
            Duration: '13h 40m',
            ExtraInfo: 'Arrives next day'
        },
        {
            Airline: 'Qantas',
            FlightNo: 'QF36',
            Date: 'Saturday 7th September 2024',
            DepartureCity: 'Singapore',
            ArrivalCity: 'Melbourne (Tullamarine)',
            DepartureTime: '20:15',
            ArrivalTime: '05:35',
            Duration: '07h 20m',
            ExtraInfo: 'Arrives next day'
        },
        {
            Airline: 'Qantas',
            FlightNo: 'QF1009',
            Date: 'Sunday 8th September 2024',
            DepartureCity: 'Melbourne (Tullamarine)',
            ArrivalCity: 'Hobart',
            DepartureTime: '08:30',
            ArrivalTime: '09:45',
            Duration: '01h 15m',
            ExtraInfo: ''
        }
    ];

    const adelaideDeparting = [
        {
            Airline: 'Qantas',
            FlightNo: 'QF887',
            Date: 'Sunday 1st September 2024',
            DepartureCity: 'Adelaide',
            ArrivalCity: 'Perth',
            DepartureTime: '11:40',
            ArrivalTime: '13:35',
            Duration: '03h 25m',
            ExtraInfo: ''
        },
        {
            Airline: 'Qantas',
            FlightNo: 'QF9',
            Date: 'Monday 2nd September 2024',
            DepartureCity: 'Perth',
            ArrivalCity: 'London Heathrow',
            DepartureTime: '18:30',
            ArrivalTime: '05:05',
            Duration: '17h 35m',
            ExtraInfo: 'Arrives next day'
        },
        ]
        const adelaideReturn = [
        {
            Airline: 'Qantas',
            FlightNo: 'QF10',
            Date: 'Friday 6th September 2024',
            DepartureCity: 'London Heathrow',
            ArrivalCity: 'Perth',
            DepartureTime: '11:55',
            ArrivalTime: '11:40',
            Duration: '16h 45m',
            ExtraInfo: 'Arrives next day'
        },
        {
            Airline: 'Qantas',
            FlightNo: 'QF886',
            Date: 'Saturday 7th September 2024',
            DepartureCity: 'Perth',
            ArrivalCity: 'Adelaide',
            DepartureTime: '14:25',
            ArrivalTime: '18:45',
            Duration: '02h 50m',
            ExtraInfo: ''
        }
    ];
    const perthDeparting = [
        {
            Airline: 'Qantas',
            FlightNo: 'QF9',
            Date: 'Sunday 1st September 2024',
            DepartureCity: 'Perth',
            ArrivalCity: 'London Heathrow',
            DepartureTime: '18:30',
            ArrivalTime: '05:05',
            Duration: '17h 35m',
            ExtraInfo: 'Arrives next day'
        },]
    const perthReturn = [
        {
            Airline: 'Qantas',
            FlightNo: 'QF10',
            Date: 'Friday 6th September 2024',
            DepartureCity: 'London Heathrow',
            ArrivalCity: 'Perth',
            DepartureTime: '11:55',
            ArrivalTime: '11:40',
            Duration: '16h 45m',
            ExtraInfo: 'Arrives next day'
        }
    ];

    const melbourneDeparting = [
        {
            Airline: 'Qantas',
            FlightNo: 'QF37',
            Date: 'Sunday 1st September 2024',
            DepartureCity: 'Melbourne (Tullamarine)',
            ArrivalCity: 'Singapore',
            DepartureTime: '15:50',
            ArrivalTime: '22:05',
            Duration: '08h 15m',
            ExtraInfo: ''
        },
        {
            Airline: 'Qantas',
            FlightNo: 'QF1',
            Date: 'Monday 2nd September 2024',
            DepartureCity: 'Singapore',
            ArrivalCity: 'London Heathrow',
            DepartureTime: '23:15',
            ArrivalTime: '06:35',
            Duration: '14h 20m',
            ExtraInfo: 'Arrives next day'
        },
    ]
    const melbourneReturn = [
        {
            Airline: 'Qantas',
            FlightNo: 'QF2',
            Date: 'Friday 6th September 2024',
            DepartureCity: 'London Heathrow',
            ArrivalCity: 'Singapore',
            DepartureTime: '20:45',
            ArrivalTime: '17:25',
            Duration: '13h 40m',
            ExtraInfo: 'Arrives next day'
        },
        {
            Airline: 'Qantas',
            FlightNo: 'QF36',
            Date: 'Sunday 8th September 2024',
            DepartureCity: 'Singapore',
            ArrivalCity: 'Melbourne (Tullamarine)',
            DepartureTime: '20:15',
            ArrivalTime: '05:35',
            Duration: '07h 20m',
            ExtraInfo: 'Arrives next day'
        }
    ];

    const FlightTable = (flights) => {
 
        return flights.map((flight, i) => {

        let airlineLogo = `https://newsunfiles.blob.core.windows.net/iceworks-24-images/${flight.Airline}.png`
            let flightDate = ' ';
        //Note for future developer: These conditionals are some spaghetti nonsense, I apologies, just burn it down and start again - K
            if ((flights.length > 3 && i === 2) || i === 0) flightDate = `Departing ${flight.Date}`
            else if ((flights.length > 3 && i%2!==0) || i === flights.length - 1) {
                flightDate =`Arriving ${flight.Date}`
            }
        return (
            <Col className="p-0" lg={12 / (flights.length > 3 || flights.length < 2 ? 2: flights.length)}>
            <div className="flight-card bg-light p-3 my-2 rounded">
                    <div className="d-flex justify-content-between align-items-center mb-2"
                        style={{height: '50px'} }>
                        <h4 className="text-bold m-0"><p>{flightDate}</p></h4>
                        {((flights.length > 3 && i % 2 !== 0) || i === flights.length - 1) && (<div className="airline-logo">
                            <img width={50} height={50} src={airlineLogo} alt={flight.Airline} />
                        </div>)}
                </div>
                <div className="flight-stop mb-1 p-2 rounded" style={{ border: "1px solid rgb(228, 228, 228)" }}>
                        <div className="destination-row d-flex justify-content-between">
                            <p><b>{flight.DepartureCity}</b></p>
                            <p className="text-end"><b>{flight.ArrivalCity}</b></p>
                    </div>
                        <div className="schedule-row d-flex justify-content-between align-items-center">
                            <p className="m-0">{flight.DepartureTime}</p>
                        <span className="timeline"></span>
                            <p className="m-0">{flight.ArrivalTime}</p>
                        </div>
                        <p className="my-2 text-danger text-bold">{flight.FlightNo}</p>
                </div>
                    <p className="m-0">Flight duration: {flight.Duration}</p>
            </div>
         </Col>
            )
        })
    }
  
    return (
        <>
            {isflightDetails && (< Container className="flights-summary">
                <Fade triggerOnce>
                    <h2 className="text-center text-bold display-4 py-4">Flights</h2>
                    <h4>Group Flights</h4>
                    <p>See below our exclusive group flights for Iceworks 24. Group flights have been organised from major ports out of Australia. If your home port is not listed in the table below, you will have the opportunity to select your home port during the registration process and one of our Travel Managers will contact you to book in your flight.</p>
                    <p>
                    Please Note: All flights are booked 'subject to availability' and while we will endeavor to accommodate your request there is no guarantee. Any flight changes will be communicated to you through our Travel Managers.
                    </p>
                </Fade>
            </Container>)}

            {isflightDetails && (<Container>

                <Accordion>
                    <Accordion.Item className = "pt-1" eventKey="0">
                        <Accordion.Header className="text-bold">Sydney</Accordion.Header>
                        <Accordion.Body>
                            <hr />
                            <Row>
                                <h3 className="mt-3 mb-0">Departure</h3>
                                {FlightTable(sydneyDeparting)}
                            </Row>
                            <Row>
                                <h3 className="mt-3 mb-0">Return</h3>
                                {FlightTable(sydneyReturn)}
                            </Row>
                        </Accordion.Body>
                   </Accordion.Item>
                    <Accordion.Item className="pt-1" eventKey="1">
                        <Accordion.Header className="text-bold">Melbourne</Accordion.Header>
                        <Accordion.Body>
                    <hr />
                    <Row>
                        <h3 className="mt-3 mb-0">Departure</h3>
                        {FlightTable(melbourneDeparting)}
                    </Row>
                    <Row>
                        <h3 className="mt-3 mb-0">Return</h3>

                            { FlightTable(melbourneReturn) }
                    </Row>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item className="pt-1" eventKey="2">
                        <Accordion.Header className="text-bold">Brisbane</Accordion.Header>
                        <Accordion.Body>
                    <hr />
                    <Row>
                        <h3 className="mt-3 mb-0">Departure</h3>
                        {FlightTable(brisbaneDeparting)}
                    </Row>
                    <Row>
                        <h3 className="mt-3 mb-0">Return</h3>
                        {FlightTable(brisbaneReturn)}
                    </Row>
                    </Accordion.Body>
                </Accordion.Item>

                    <Accordion.Item className="pt-1" eventKey="3">
                        <Accordion.Header className="text-bold">Adelaide</Accordion.Header>
                        <Accordion.Body>
                    <hr />
                    <Row>
                        <h3 className="mt-3 mb-0">Departure</h3>
                        {FlightTable(adelaideDeparting)}
                    </Row>
                    <Row>
                        <h3 className="mt-3 mb-0">Return</h3>
                        {FlightTable(adelaideReturn)}
                    </Row>
                    </Accordion.Body>
                </Accordion.Item>

                    <Accordion.Item className="pt-1" eventKey="4">
                        <Accordion.Header className="text-bold">Perth</Accordion.Header>
                        <Accordion.Body>
                    <hr />
                    <Row>
                        <h3 className="mt-3 mb-0">Departure</h3>
                        {FlightTable(perthDeparting)}
                    </Row>
                    <Row>
                        <h3 className="mt-3 mb-0">Return</h3>
                        {FlightTable(perthReturn)}
                    </Row>
                   </Accordion.Body>
                </Accordion.Item>

                    <Accordion.Item className="pt-1" eventKey="5">
                        <Accordion.Header className="text-bold">Hobart</Accordion.Header>
                        <Accordion.Body>
                    <hr />
                    <Row>
                        <h3 className="mt-3 mb-0">Departure</h3>
                        {FlightTable(hobartDeparting)}
                    </Row>
                    <Row>
                        <h3 className="mt-3 mb-0">Return</h3>
                        {FlightTable(hobartReturn)}
                    </Row>
                         </Accordion.Body>
                </Accordion.Item >
                </Accordion>
            </Container>)}

            <Container className="my-3">
                <Fade triggerOnce>
                    <h4 className="pt-3">Pre & Post Flight Extension</h4>
                    <p>If you wish to fly to London earlier, extend your stay after Iceworks 24 or looking to travel to other countries in Europe, our dedicated travel team is here to help you with any flight changes or travel extensions from Iceworks 24. During the registration process you can advise the date you intend to depart and return to your home port and one of our Travel Managers will contact you to help create the perfect itinerary for your Iceworks 24 adventure.</p>
                    <p> Our travel managers can also help you find the best accommodation options, tours, car hire or anything else required to make your trip the perfect extension to Iceworks 24.</p>
                   <p> Please Note: Any changes to the current group date flights will impose an airline fee of $100.00 per person plus any fare difference, and all travel arrangements must be paid in full at time of booking. </p>

                    <h4 className="pt-3">Flight Upgrade Information</h4>
                <p>If you wish to upgrade your flights, please specify in the "Flight Upgrade Request" box on the registration page. You will need to specify if you wish to upgrade using points or via paying direct for the difference. 
                <br/>
                <br/>
                If you are upgrading using points, we will need to ticket your flights and provide you with your individual e-ticket for you to request the upgrade directly with the airline, this will only be made available 14 days prior to departure due to flights being part of the Group block.
                <br/>
                <br/>
                If you wish to pay the difference the Iceworks 24 team will be in contact with a quote. Kindly note all payments must be paid in full prior to travel and seats will be on a first served basis dependant on availability at time of request.
                </p>
                
                    <h4 className="pt-3">Flight Upgrades</h4>
                    <ul>
                        <li>
                            SMILES cannot be used for flight upgrades. 
                        </li>
                        <li>
                            To upgrade flights, please speak to one of the Iceworks 24 events team.
                        </li>
                        <li>
                            Upgrades must be paid in full at time of ticketing and the airfare will be strictly subject to fare availability at the time of booking, and valid on day of inquiry only.
                        </li>
                    </ul>
                
                    <h4 className="pt-3"> Frequent Flyer Information</h4>
                <p>Frequent flyer points are earned on all group flights for Iceworks 24. You must be a member of the Frequent Flyer Program to earn points. Membership and points are subject to the terms and conditions of the airline. Please get in contact with the Iceworks 24 events team for further information on this. 
                </p>
                
                <h4 className="pt-3">Airport Transfers</h4>
                <p>Airport transfers will be provided for all delegates arriving on group flights into London on Monday 2nd September and departing on Friday 6th September. If you intend to extend your stay or book alternate flights, you will need to arrange your own airport transfers in accordance with your flight booking. More information about transportation can be found on the Iceworks 24 Important Information web page.
                </p>

                    <h4 className="pt-3">Dietary Requirements</h4>
                <p>Please advise the Iceworks 24 events team of your dietary requirements at time of registration so meals can be confirmed prior to travel. For any advice or assistance with meals available when travelling on the airline, please refer to their website or speak to a member of the Iceworks 24 events team.
                </p>
                
                    <h4 className="pt-3">Flight Amendments &amp; Cancellation</h4>
                <p>
                Individual flight deviations are permitted from the group travel dates. Please see below for further details of the terms and conditions 
                <br/>
                <br/>
                All flight changes are to be requested at time of registration. 
                <br/>
                <br/>
                Change requests from initial time of registration to 45 days out from travel (19th July 2024 – for people travelling on the group dates) individual flight deviations from group flights are permitted for a deviation fee per airline of $65.00 per person up to 45 days prior to travel, plus any fare difference. 
                <br/>
                <br/>
                Any fare differential will be quoted at time of request. Change requests within 45 days (from 19th July 2024 onwards - for people travelling on the group dates) out from travel, up until 72 hours prior to travel – individual flight deviations from the group are permitted but will incur a fee of $120.00 per person in addition to any applicable fare differential which will be quoted at time of request.
                <br/>
                <br/>
                Changes are not permitted inside 48 hours from travel. The purchase of a new airfare will be required. Change requests after departure date – change fee of $155 per person applies in addition to any applicable fare differential which will be quoted at time of request. 
                <br/>
                <br/>
                All cancellations are to be made to the Iceworks 24 event team in writing. Cancellation fees may apply for any flight bookings held in your name. Please note flight bookings are held in your name immediately upon registration.
                </p>
                </Fade>
            </Container>
        </>
    )
}

export default Flights;