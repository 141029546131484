import styled from "styled-components";


export const AgendaCard = styled.div`
    padding: 10px;
    max-width: 500px;

    h4{
        margin: 10px 0;
    }

    p{
        line-height: var(--text-line-height);
        font-size: var(--text-font-size);
    }


    @media only screen and (min-width: 1024px){
        h4{
            font-size: 25px
        }

        width: 500px;
    }

`;


export const AgendaBody = styled.div`
    margin: 30px 0;
    display: flex;
    justify-content:center;
    flex-wrap: wrap;

    @media only screen and (min-width: 1024px){
        flex-wrap: nowrap;
    }
`;


export const AgendaDateTab = styled.button`
    width: 50%;
    padding: 20px;
    background-color: transparent;
    border: none;
    transition: 0.5s border; ease;
    border-bottom: ${props => props.active ? '4px solid black' : '4px solid transparent'};
    font-family: var(--text-font);

    &:hover{
        border-bottom: 4px solid black;
        cursor: pointer;
    }

    h4{
        font-size: 20px;
    }

    h6{
        font-size: 14px;
        margin-bottom: 10px;
        font-weight: normal;
    }

    @media only screen and (min-width: 1024px){
       h4{
            font-size: 25px;
       }
    }
`;


export const AgendaSection = styled.section`
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content:center;
    padding: var(--container-padding-mobile);
    header {
        display: flex;
        border-bottom: 1px solid #f3f3f3;
        overflow:scroll;
    }



    @media only screen and (min-width: 1024px){
        header {
            overflow:inherit;
        }
     }
`;


