import { useEffect, useReducer } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import { getHotelpageContentAsync } from '../../services/strapi';
import contentReducer, { INITIAL_STATE } from '../../state/state';

import "./hotel.style.scss"
import { Fade } from 'react-awesome-reveal';

const Hotel = () => {

    return(
        <>

            <header>
            <Container fluid className='p-0'>
                    <Carousel style={{ height: '60vh' }}>
                        <Carousel.Item className="h-100">
                            <img
                            className="d-block w-100 h-100"
                                src="https://newsunfiles.blob.core.windows.net/iceworks-24-images/Hilton_Restaurant.jpg" 
                            alt="First slide"
                            />
                                <div className="carousel__overlay">
                                    <Carousel.Caption >
                                    <h2 id="wide">Tyburn Kitchen</h2>
                                    <p id="wide">Tyburn Kitchen focuses on honesty and seasonality, with 90% of its ingredients sourced within 100 miles of the hotel. Try charcoal grilled meats and plant-powered dishes.</p>
                                    <h2 id="narror">Tyburn Kitchen</h2>
                                </Carousel.Caption>
                            </div>        
                        </Carousel.Item>
                        <Carousel.Item className="h-100">
                            <img
                            className="d-block w-100 h-100"
                                src="https://newsunfiles.blob.core.windows.net/iceworks-24-images/Hilton_Bar.webp"  
                            alt="First slide"
                            />
                            <div className="carousel__overlay">
                                <Carousel.Caption>
                                    <h2 id="wide">Bow Bar</h2>
                                    <p id="wide">Bow Bar starts the day as a warm meeting spot for coffee and light bites. In the evening, enjoy a range of craft cocktail.</p>
                                    <h2 id="narror">Bow Bar</h2>
                                </Carousel.Caption>
                            </div>
                        </Carousel.Item>
                    </Carousel>
                </Container>
            </header>

            <Container className="hotel-summary py-6 text-center">
                   <Fade triggerOnce>
                        <Row className="align-items-center">
                            <Col md={8} className="mx-auto">
                                <h3 className='display-5 text-bold'>About the Hotel</h3>
                                <p>
                                Located in central London, the Hilton London Metropole offers four themed bars and restaurants, a gym, and an executive lounge. Conveniently located near major attractions like Hyde Park and Oxford Street, it offers easy access to explore all that London has to offer.
                                </p>
                            </Col>
                        </Row>
                   </Fade>
            </Container>



            <Container fluid className="city-view-banner py-5 bg-light position-relative " style={{ backgroundImage: "url(https://newsunfiles.blob.core.windows.net/iceworks-24-images/Hilton_Ext.jpg"}}>
               <div className='banner-overlay d-flex justify-content-center align-items-center  text-center text-white'>
                    <Row className='w-100'>
                        <Fade triggerOnce>
                            <Col lg={12}>
                                <h3 className="display-5 text-bold">Located in the heart of London</h3>
                            </Col>
                        </Fade>
                    </Row>
               </div>
            </Container>


            <Container className="py-5">
                <Fade triggerOnce>
                    <Row className='align-items-center pb-4'>
                        <h3 className='display-5 text-bold'>Accommodation</h3>
                   <Col lg={6}>
                            <h4>Room Types</h4>
                            <p>Hilton London Metropole’s interiors present a contemporary ambience with impeccable details. With two room types to choose from, the rooms offer great views, elegant bathrooms, and modern touches for a comfortable stay.</p>
                            <ul>
                                <li><b>Standard Guest Rooms</b> offer a comfortable queen bed and a selection of amenities to help you relax and unwind during your stay. This room can accommodate a maximum of two people sharing a bed.</li>
                                <li><b>Twin Guest Rooms</b> offer 2 x queen beds dressed in a contemporary style and decorated in rich, warm tones. This room can accommodate a <b>maximum of four people with two people per bed.</b> This is also the ideal room selection for two travellers requiring separate beds.</li>
                            </ul>
                            <img className="rounded mt-2" height={350} src="https://newsunfiles.blob.core.windows.net/iceworks-24-images/Hilton_Room.webp" />
                   </Col>
                   <Col lg={6}>
                            <img className="rounded mt-4" height={350} src="https://newsunfiles.blob.core.windows.net/iceworks-24-images/Hilton_Room2.avif" />
                            <p className="mt-5">
                                The room amenities are as follows:
                            </p>
                            <Container>
                                <Row >
                                    <Col xs={6}>
                                        <ul>
                                            <li>Work Desk</li>
                                            <li>Bath & Shower</li>
                                            <li>LCD TV</li>
                                            <li>Air Conditioning</li>
                                            <li>Mini Refrigerator</li>
                                            <li>Hair Dryer</li>
                                        </ul>
                                    </Col>

                                    <Col xs={6}>
                                        <ul  >
                                            <li>Bathroom Amenities</li>
                                            <li>Mini Bar</li>
                                            <li>Room Service</li>
                                            <li>Tea & Coffee</li>
                                            <li>Telephone</li>
                                            <li>Safe</li>
                                        </ul>

                                    </Col>
                                    <p>Wi-Fi is free for Hilton Honours members; guests can become members at check-in. Membership is free.</p>
                                    <p></p>
                                </Row>
                            </Container>
                   </Col>


                </Row> 
                </Fade>
               
                <h3>Additional Nights</h3>
                <p>Additional nights can be purchased directly from BX Agency for pre & post group dates by contacting us directly. The additional nightly rate at the Hilton London Metropole is:</p>
                <ul className='mb-1'>
                    <li>Standard Guest Rooms (Single Occupancy) from $550.00 per night (inclusive of breakfast & taxes for one person)</li>
                    <li>Standard Guest Rooms (Double Occupancy) from $590.00 per night (inclusive of breakfast & taxes for two people)</li>
                    <li>Twin Guest Rooms (Double Occupancy) from $700.00 per night (inclusive of breakfast & taxes for two people)</li>
                    <li>Twin Guest Rooms (4 x People Occupancy) from $770.00 per night (inclusive of breakfast & taxes for two people)</li>
                </ul>
                <sub>**All rooms are subject to availability at time of booking**</sub>
                <p className="pt-3">
                    Please be aware that the hotel room capacity is limited to a maximum of 4 guests in a 'Twin Room'. If your travelling party exceeds 4 guests you will need to book two rooms and contact BX Agency to find you an appropriate room.
                    Please note additional fees may apply when booking more than one room, which will be invoiced to you upon completing your registration.
                </p>

                <h3 className="display-7 text-bold pt-3">Incidental Expenses</h3>
                        <p className="m-0">
                        All items charged back to your room for incidental purchases in the hotel including mini-bar, room service, food & beverage purchases at the hotel’s bars or restaurants, spa, laundry, telephone, or business services are your own responsibility and must be settled prior to leaving the hotel.

                        </p>
                
            </Container>           
        </>
    )
}

export default Hotel;