import {  Container } from 'react-bootstrap';


import "./important-information.style.scss";

const items = [
    {
        title: ' Visa and Passport',
        content: `<p>All travellers must carry a machine-readable passport valid for at least six months beyond the date of departure. Travel advice is available from the Australian Government at <a href="https://www.smartraveller.gov.au">www.smartraveller.gov.au</a>. If you are a non- Australian passport holder, you will need to check your Visa Requirements online at <a href="https://www.gov.uk/check-uk-visa">www.gov.uk/check-uk-visa</a>. Managing Visa requirements are the sole responsibility of the individual traveller and a valid visa must be obtained before travelling to the UK.</p>
        <p><strong>Do Australian passport holders need a visa for London?</strong></p>
    <p>No, Australians who enter London as a tourist or standard visitor may stay for up to 6 months without a visa as long as they hold an Australian passport which remains valid during their stay and is still valid when departing the UK.</p>
<p>Travellers entering or leaving Australia do not need to provide evidence of vaccination status.
We do recommend travelling with your International Vaccination Certificate which can be obtained from myGov or the Express Plus Medicare Mobile App.</p>`},
    //{
    //    title: '01. Haneda Airport',
    //    content: 'Haneda is located in the city, while Narita is a part of Chiba prefecture, on the eastern outskirts of Tokyo. This means commuting to the city centre from Haneda will save you time and money. With its amazing public transportation system, you can easily choose between trains or buses.'
    //},
    {
        title: 'Health',
        content: `<p>Remember to advise staff of any pre-existing health conditions, injuries, or if you are (or think you may be) pregnant at your earliest convenience. This ensures that the appropriate arrangements can be made for your safety.</p>
        <ul>
            <li>Name</li>
            <li>Blood Type</li>
            <li>Any illness currently being treated</li>
            <li>Any medicines you are taking</li>
            <li>Allergies</li>
            <li>Medical History</li>
            <li>Religion</li>
            <li>Emergency Contact Information</li>
            </ul>
        <br/>
        <p>Emergency Services: <b>999</b></p>
        <p>Non-emergency police: <b>101</b></p>
        <p>Non-emergency medical: <b>111</b></p>
        `
    },
    {
        title: 'Weather',
        content: `In September, London typically experiences mild temperatures as summer transitions to autumn. Expect daytime highs averaging around 18-22°C with cooler evenings dropping to 10-14°C. While rainfall is common, it's usually light, with occasional showers refreshing the cityscape. Overall, September in London offers a mix of comfortable temperatures, occasional rain, and glimpses of autumnal sunshine, making it ideal for exploring the city's parks, museums, and vibrant streets.`
    },
    {
        title: `Currency`,
        content: `British Pound Sterling (£), often abbreviated as GBP. It's denoted by the symbol "£". Banknotes in circulation include denominations of £5, £10, £20, and £50, while coins come in values of £2, £1, 50p, 20p, 10p, 5p, 2p, and 1p. ATMs are widely available and can be found in various locations throughout the city. The average exchange rate for the last 6 months is 1 GBP = 1.92 AUD, please check daily exchange rate as this may fluctuate. `
    },
    {
        title: 'Travelling in London',
        content: `<b>London Undergroun (Tube)</b ><br />The London Underground, often referred to simply as the Tube, is the oldest underground railway system in the world and an iconic feature of London's transportation network.It serves as a vital means of transportation for millions of commuters and tourists each day, connecting various parts of the city and surrounding areas.The hotel is located across the road from the entrance to the <b>Edgeware Rd </b> tube station, only a 2 minute walk. <br/>
        <b>Extensive Network:</b><br/>
The Tube comprises 11 lines, covering approximately 402 kilometres (250 miles) of track and serving 270 stations. It spans across Greater London and extends into neighbouring counties such as Buckinghamshire, Essex, and Hertfordshire. <b>Paddington Station</b> is an 8 minute walk from the hotel, connecting delegates to neighbouring counties, with a direct line to Gatwick airport.
`

    },
    {
        title: 'Taxi',
        content: `Taxis in London, commonly known as black cabs, are a popular and convenient mode of transportation for both locals and visitors. Black cabs in London operate on metered fares, and can be hailed on the street or found at designated taxi ranks throughout the city. Additionally, passengers can book black cabs in advance through taxi booking apps or by calling a taxi company. 
        </br>
        In addition to black cabs, London also has a growing number of ride-hailing services, such as Uber, Bolt, and Kapten, which offer an alternative to traditional taxis. These services operate through smartphone apps and provide a convenient way to book and pay for rides. `
    },
    {
        title: 'Car Rental',
        content: `In London, you can find several major car rental agencies offering a wide range of vehicles to suit different needs and preferences. Some of the main car rental agencies in London include:<br/>
Hertz, Avis, Enterprise, Budget, Sixt, Europcar.
These are just a few examples of the main car rental agencies in London. Each of these companies typically offers online booking services, allowing you to reserve a vehicle in advance and choose from various pickup and drop-off locations across the city.`
    }
    
]

const ImportantInformation = () => {
    
    const display = items.map((item, index) =>
        <Container>
            <ul className='text-white px-2' style={{ listStyleType: "none" }}>
            <li>
                    <p className="display-5 text-bold">{index + 1}. {item.title}</p>
                    <p dangerouslySetInnerHTML={{ __html: item.content }}>                  
                </p>
            </li>
        </ul>
        </Container>)
    return (<><Container fluid className="important-information-container">
            <div className="overlay pb-4">
            <h2 className="text-center text-bold display-4 py-5 text-white">Important Information</h2> 
            {display}
            </div>
            </Container>
            </>)   
}

export default ImportantInformation;