import React from 'react';
import { Outlet } from 'react-router-dom';
import Navigation from '../navigation/navigation.component';
import { Button } from 'react-bootstrap';


const Layout = () => {

    return (
        <> 
            <Navigation/>          
            <div id='content'>
                <Outlet/>
            </div>
            <footer className="p-3 text-center bg-dark">
                <p className="text-light mb-2">© {new Date().getFullYear()} Iceworks | <a href="https://newsunrise.com.au/">New Sunrise</a></p>
                <p className="text-light mb-2">Call us: <a href="tel:1300 570 025">1300 570 025</a></p>
                <p className="text-light mb-2"><a href="mailto: info@iceworks24.com.au">info@iceworks24.com.au</a></p>
            </footer>

            {/* <Button variant="dark">
                <i className='fas fa-plane-departure fa-5x'></i>
            </Button> */}
        </>
    )

}


export default Layout;