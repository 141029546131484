import { useState } from "react";
import { Button, Card, Col, Container, Row, Modal } from "react-bootstrap";

const tourdetails = [
    {
        imageUrl: 'https://newsunfiles.blob.core.windows.net/iceworks-24-images/Paris.webp',
        title: 'Paris',
        summary: `<ul>
                    <li>3 nights in Paris at the Hôtel Moliere </li>
                    <li>Return Eurostar Rail to Paris ex London</li>
                    <li>Including 1 dinner at the Eiffel Tower: Madame Brasserie Restaurant  </li>
                    <br><br>
                    </ul>`,
        cost: '<b>Total Cost for 3 nights for 2 adults from*: AUD$3,840</b>',
        modalContent: `&nbsp;&nbsp;&nbsp;&nbsp;
        `,
    },
    {
        imageUrl: 'https://newsunfiles.blob.core.windows.net/iceworks-24-images/Dublin.webp',
        title: "Dublin",
        summary: `<ul>
                    <li>3 nights in Dublin at the The College Green Hotel Dublin </li>
                    <li>Return Airport – Hotel Transfers</li>
                    <li>Tour: 4 hour tour of Dublin Jameson Distillery and Guinness Storehouse</li>
                    </ul>`,
        cost: 'Total Cost for 3 nights for 2 adults from*: AUD$2,986',
        modalContent: `&nbsp;&nbsp;&nbsp;&nbsp;
        `,
    },
    {
        imageUrl: 'https://newsunfiles.blob.core.windows.net/iceworks-24-images/Barcelona.avif',
        title: "Barcelona",
        summary: `<ul>
                    <li>3 nights Barcelona at Hotel 1898 </li>
                    <li>Return Airport – Hotel Transfers</li>
                    <li>Fast Track Tour: Sagrada Familia Guided Tour</li>
                    </ul>`,
        cost: '<b>Total Cost for 3 nights for 2 adults from*: AUD$2,282</b>',
        modalContent: `&nbsp;&nbsp;&nbsp;&nbsp;

        `,
    },
    {
        imageUrl: 'https://newsunfiles.blob.core.windows.net/iceworks-24-images/Rome.webp',
        title: "Rome",
        summary: `<ul>
                    <li>3 nights at the Hotel Quirinale </li>
                    <li>Return Airport transfers</li>
                    <li>Tour: Colosseum & Ancient Rome Guided Tour with Roman Forum & Palatine Hill</li>
                    </ul>`,
        cost: '<b>Total Cost for 3 nights for 2 adults from*: AUD$1,790</b>',
        modalContent: `&nbsp;&nbsp;&nbsp;&nbsp;
        `,
    }, 
    
];


const MiniStays = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    
    const [modalContent, setModalContent] = useState("");

    
    return (
        <>
        <Container>
                <h3 className="display-4 mt-1 text-center text-bold py-5"  >Mini Stay</h3>
                <p className="py-4">Embark on a whirlwind adventure across Europe with mini stays that promise big memories! From the romance of Paris to the history of Rome, each city offers its own unique charm and allure. Explore quaint cobblestone streets, indulge in delectable cuisine, and marvel at iconic landmarks that have stood the test of time. Whether you're craving culture, adventure, or relaxation, Europe has it all in compact, bite-sized experiences. Seize the opportunity to discover the continent's rich tapestry of cultures and landscapes. Book your mini stay in Europe today and let the journey begin!</p>
            <Row >
                    {tourdetails.map(item => {
                        return (
                            <Col style={{ marginBottom: '15px' } }>
                                <Card style={{ height: '100%', width: '18rem' }}>
                                    <Card.Img variant="top" style={{ height:'200px' }} src={item.imageUrl} />
                                <Card.Body >
                                    <Card.Title>{item.title}</Card.Title>
                                        <Card.Text style={{ height: '85%' }}>
                                        <p style={{height: '75%' }} dangerouslySetInnerHTML={{__html: item.summary}}></p>
                                        <b dangerouslySetInnerHTML={{__html: item.cost}}></b>
                                    </Card.Text>
                                {/*        <Button variant="info" onClick={() => { handleShow(); setModalContent(item.modalContent); }} >More Info</Button>*/}
                                </Card.Body>
                            </Card>
                        </Col>
                        )
                    })}
                </Row>


            <Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton> <h4>Information</h4>
                </Modal.Header>
                <Modal.Body dangerouslySetInnerHTML={{ __html: modalContent }} style={{ textAlign: 'justify', margin: '4px'}}></Modal.Body>
                <Modal.Footer >
                    <Button variant="info" onClick={handleClose} >
                        Close
                    </Button>
                    
                    </Modal.Footer>
                </Modal>
                <Row className="bg-primary ps-5 align-items-end">
                    <Col className="mt-3"  lg={5}>
                        <p className="px-4 py-4 my-0 fs-5">
                            Reach out to one of our experienced travel managers to book your Mini Stay adventure now!
                        </p>
                    <Button variant="secondary" className="ms-5" size="lg" href="mailto: info@iceworks24.com.au?subject=Iceworks 24 Stays Enquiry">Enquire Now!</Button>
                    </Col>
                    <Col className= "px-5 d-flex justify-content-end" lg={7}>
                        <img height={'150px'} src="https://newsunfiles.blob.core.windows.net/iceworks-24-images/Travel_Footer.png" alt="Banner" />
                </Col>
                </Row>
            </Container>
            </>
    )
}

export default MiniStays;