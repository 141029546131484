import { Col, Container, Row } from "react-bootstrap";

import "./about.style.scss";
import { Fade } from "react-awesome-reveal";

const About = () => {
    return(
        <>

            <header className="about-header d-flex justify-content-center align-items-center" style={{ backgroundImage: "url(https://newsunfiles.blob.core.windows.net/iceworks-24-images/Iceworks_24_images.webp)"}}>
            <Fade triggerOnce>
                    <h2 className="display-2 text-bold text-white">Explore London</h2>
            </Fade>
        </header>


        <Container className="about-summary">
            <Fade triggerOnce>
                <p>
                        Welcome to London, where innovation meets tradition, and business thrives in the midst of historical grandeur. Join us at iceworks 24 our annual conference bringing together the best independent retailers in Australia, to inspire, engage, and propel your organisation forward.
                </p>
                <p>
                        Set against the backdrop of this vibrant city, iceworks 24 is where ideas converge, connections flourish, and possibilities are limitless. Embrace the energy of London as we embark on a journey of collaboration, knowledge sharing, and transformative experiences. Welcome to a gathering where success knows no bounds. Welcome to iceworks 24 in London. 

                </p>
            </Fade>
        </Container>

        <Container className="districts-container">
            <Fade triggerOnce>
            <Row>
            <Col lg={3}>
                            <div className="district-card position-relative rounded my-2 px-2 py-3" style={{ backgroundImage: "url(https://newsunfiles.blob.core.windows.net/iceworks-24-images/BuckinghamPalace.jpg)"}}>
                                <h3 className="text-white">Buckingham Palace</h3>
                    <div className="district-card__overlay px-2 py-3 text-white">
                        <h3>Buckingham Palace</h3>
                        <p>
                        The official residence of the British monarch, Buckingham Palace is a symbol of royal grandeur and pageantry in central London.                         <br/>
                        <br/>
                        Witness the iconic Changing of the Guard ceremony, a centuries-old tradition that showcases precision marching and colorful uniforms.                         <br/>
                        <br/>
                        Explore the opulent State Rooms during the summer months, adorned with exquisite artworks, lavish furnishings, and royal treasures.                        </p>
                    </div>
                </div>
            </Col>
            <Col lg={3}>
                            <div className="district-card position-relative rounded my-2 my-2 px-2 py-3" style={{ backgroundImage: "url(https://newsunfiles.blob.core.windows.net/iceworks-24-images/hyde-park.avif"}}>
                <h3 className="text-white">Hyde Park</h3>
                    <div className="district-card__overlay px-2 py-3  text-white">
                    <h3>Hyde Park</h3>
                        <p>
                        Hyde Park, an enchanting oasis nestled in the heart of London, invites visitors to escape the city's hustle and bustle and immerse themselves in natural beauty and tranquility. 
                        <br/>
                        <br/>
                        Spanning over 350 acres, this historic park is a cherished green space that offers a myriad of activities and attractions for all ages.
                        <br/>
                        <br/>
                       Take a leisurely stroll along the serene Serpentine Lake, rent a rowboat for a charming water excursion, or relax on the lush lawns surrounded by majestic trees and vibrant flower beds.
                        <br/>
                        <br/>
                         History buffs can explore the park's landmarks such as the iconic Diana, Princess of Wales Memorial Fountain and the majestic Marble Arch.
                        </p>
                    </div>
                </div>
            </Col>
            <Col lg={3}>
                            <div className="district-card position-relative rounded my-2 px-2 py-3" style={{ backgroundImage: "url(https://newsunfiles.blob.core.windows.net/iceworks-24-images/tower-london.avif"}}>
                                <h3 className="text-white">The Tower of London</h3>
                    <div className="district-card__overlay px-2 py-3  text-white" >
                     <h3>The Tower of London</h3>
                        <p>
                        Steeped in centuries of royal history and intrigue, the Tower of London is an iconic fortress and UNESCO World Heritage Site in central London.
                        <br/>
                        Explore the medieval fortress, where kings and queens once resided and where the Crown Jewels are displayed in all their dazzling splendor.
                        <br/>
                        </p>
                    </div>
                </div>
            </Col>
            <Col lg={3}>
                            <div className="district-card position-relative rounded my-2 px-2 py-3" style={{ backgroundImage: "url(https://newsunfiles.blob.core.windows.net/iceworks-24-images/museum1.jpeg"}}>
                                <h3 className="text-white">The British Museum</h3>
                    <div className="district-card__overlay px-2 py-3 text-white">
                        <h3>The British Museum</h3>
                        <p>
                        One of the world's most renowned museums, the British Museum in central London is a treasure trove of human history and culture.
                        <br/>
                        <br/>
                        Housing a vast collection of artifacts spanning millennia and continents, including the Rosetta Stone and the Elgin Marbles, the museum offers a captivating journey through civilizations. Explore ancient Egyptian mummies, Greek sculptures, and priceless relics from around the globe.
                        </p>
                    </div>
                </div>
            </Col>
           </Row>
            </Fade>
          
        </Container>

        {/*    <Container  style={{ maxWidth: "1200px", textAlign: 'justify'}}>*/}
        {/*   <Fade triggerOnce>*/}
        {/*   <div className="text-center">*/}
        {/*        <h3 className="display-5  mb-3">Discover the Best Food Experiences in London </h3>*/}
                
        {/*        <p>*/}
        {/*        Tokyo has numerous restaurants and holds the record for the most Michelin stars worldwide. The wide range of cuisine options, including traditional washoku and international dishes, ensures a remarkable dining experience. Travelers can also enjoy the local Izakaya, where they can try Japanese beer and grilled chicken skewers, as the locals do. */}
        {/*        </p>*/}
        {/*   </div>*/}
        {/*   </Fade>*/}

        {/*    <Fade triggerOnce>*/}
        {/*        <Row className="align-items-center py-3">*/}
        {/*            <Col lg={4}>*/}
        {/*                <div className="image-wrapper">*/}
        {/*                        <img className="rounded" src="https://newsunfiles.blob.core.windows.net/iceworks-images/Images/Images/about/photo-1553621042-f6e147245754.avif"/>*/}
        {/*                </div>*/}
        {/*            </Col>*/}
        {/*            <Col lg={8}>*/}
        {/*                    <h3 className="display-5">St. Paul's Cathedral</h3>*/}
        {/*                    <p>St. Paul's Cathedral is an architectural masterpiece and an enduring symbol of London. Visitors can climb to the top of the dome for stunning views of the city or explore the cathedral's interior, including the Whispering Gallery and the crypt*/}
        {/*                        <br /><br />*/}
        {/*                    </p>*/}
        {/*                        <h4> </h4>*/}
        {/*                    <ul><li>Uogashi Nihon-Ichi (Standing Sushi Bar)&nbsp; | 11min walk from hotel&nbsp; | &nbsp;<b> 11:00 AM – 11:00 PM</b></li> */}

        {/*                        <li>Himawari Sushi Shintoshin &nbsp;| 13 min walk from hotel&nbsp; | &nbsp;<b>11:00 AM – 3:00 PM / 5:00PM – 10:00PM </b></li> */}
                                    
        {/*                            </ul>*/}
                            

        {/*            </Col>*/}
        {/*        </Row>*/}
        {/*    </Fade>*/}


        {/*   <Fade triggerOnce>*/}
        {/*    <Row className="align-items-center py-3">*/}
        {/*                <Col lg={6} >*/}
        {/*                    <h3 className="display-5">London Eye</h3>*/}
        {/*                <p>*/}
        {/*                        Tempura are pieces or slices of meat, fish, and/or vegetables that have been covered in a special tempura batter and deep fried until they become crunchy and pale gold in colour. Tempura can be eaten by itself (perhaps with a little grated daikon and a small dish of tsuyu for dipping), or served on top of rice bowls or noodle soups.*/}
        {/*                <br /><br />*/}
        {/*                        <h4>Tempura Restaurants in Shinjuku  </h4></p>*/}
                        
        {/*                    <ul><li>Tempura Shinjuku Tsunahachi Souhonten | 18 min walk from hotel |&nbsp;<b>11:00 AM – 9:00 PM </b></li>*/}

        {/*                        <li>Funabashiya Honten | 18 min walk from hotel | &nbsp;<b>11:30 AM - 9:00PM  </b></li>*/}

        {/*                    </ul>*/}
        {/*            </Col>*/}
        {/*                <Col lg={6} >*/}
        {/*                <div className="image-wrapper">*/}
        {/*                        <img className="rounded" src="https://newsunfiles.blob.core.windows.net/iceworks-images/Images/Images/about/photo-1593357849627-cbbc9fda6b05.avif"/>*/}
        {/*                </div>*/}
        {/*            </Col>*/}
        {/*        </Row>*/}
        {/*   </Fade>*/}
           
        {/*   <Fade triggerOnce>        */}
        {/*        <Row className="align-items-center py-3">*/}
        {/*            <Col lg={4}>*/}
        {/*                <div className="image-wrapper">*/}
        {/*                        <img className="rounded" src="https://newsunfiles.blob.core.windows.net/iceworks-images/Images/Images/about/photo-1614563637806-1d0e645e0940.avif"/>*/}
        {/*                </div>*/}
        {/*            </Col>*/}
        {/*            <Col lg={8}>*/}
        {/*                <h3 className="display-5">Ramen</h3>*/}
        {/*                        <p>Perfectly chewy noodles, jammy ramen egg, juicy char siu pork, bamboo shoots, and nori sheets in a hot piping bowl of soup broth. This soul food of Japan dominates the food scene in Tokyo and it’s just getting better and better.<br /><br />*/}



        {/*                        <h4>Ramen Restaurants in Shinjuku  </h4></p>*/}
        {/*                    <ul><li>Ichiran Ramen Shinjuku City | 12 min walk from hotel |&nbsp;<b>11:00 AM – 10:30 PM </b></li>*/}

        {/*                        <li>Fūunji | 14 min walk from hotel |  &nbsp;<b>11:00 AM – 3:00 PM / 5:00PM – 9:00PM </b></li>*/}

        {/*                    </ul>*/}
        {/*            </Col>*/}
        {/*        </Row>*/}
        {/*   </Fade>*/}

        {/*    <Fade triggerOnce>*/}
        {/*        <Row className="align-items-center py-3">*/}
        {/*            <Col lg={6}>*/}
        {/*                <h3 className="display-5">Yakitori</h3>*/}
        {/*                    <p>Yakitori are tasty skewered chicken meat brushed with a sweet soy glaze, and grilled over charcoal. While chicken meat is most common, you can also find other meats and vegetables on skewers on the Yakitori menus.<br /><br />*/}
        {/*                    <h4>Yakitori Restaurants in Shinjuku  </h4></p>*/}
        {/*                    <ul><li>Omoide Yokocho (Landmark alleyway ft selection of food stalls) | 13 min walk from hotel |&nbsp;<b> 24HR </b> </li></ul>*/}
        {/*            </Col>*/}
        {/*            <Col lg={6}>*/}
        {/*                <div className="image-wrapper">*/}
        {/*                        <img className="rounded" src="https://newsunfiles.blob.core.windows.net/iceworks-images/Images/Images/about/Yakitori-grilled-skewers.webp"/>*/}
        {/*                </div>*/}
        {/*            </Col>*/}
        {/*        </Row>*/}
        {/*    </Fade>*/}
        {/*    </Container>*/}
        </>
    )
}

export default About;