
import { Button, Col, Container, Form, Row, Spinner, Stack, Dropdown } from "react-bootstrap";

import "./home.style.scss";
import Agenda from "../../components/agenda/agenda";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import { EventCountdown } from "../../components/Clock/EventCountdown";


let baseUrl = "https://iceworkstest.azurewebsites.net/";

const agendaObject = {
    "agendaDates": [
        {
            "day": 1,
            "date": "Sept 2"
        },
        {
            "day": 2,
            "date": "Sept 3"
        },
        {
            "day": 3,
            "date": "Sept 4"
        },
        {
            "day": 4,
            "date": "Sept 5"
        },
        {
            "day": 5,
            "date": "Sept 6"
        }
    ],
    "agendaSchedule": {
        "1": [
            {
                "title": "Morning",
                "text": "Attendees flying from Sydney, Melbourne and Perth are scheduled to arrive at Haneda airport at 00.00. After clearing customs, they should meet the New Sunrise and iceworks 23 team who will provide transfers to the Hilton Tokyo Hotel. It’s worth noting that most stores in Shinjuku don’t open until 10 AM.",
                "imageUrl": "https://newsunfiles.blob.core.windows.net/iceworks-images/Images/Images/home/airplane-arrival-transfer%202.png"
            },
            {
                "title": "Afternoon",
                "text": "Please note hotel check-In will begin at 3 PM. Attendees are encouraged to take advantage of the hotel amenities and explore the nearby Shinjuku district until check-in time.",
                "imageUrl": "https://newsunfiles.blob.core.windows.net/iceworks-images/Images/Images/home/reception.png"
            },
            {
                "title": "Evening",
                "text": "Attendees flying from Melbourne, Brisbane, Perth and Adelaide are scheduled to arrive at Haneda airport at …… . After clearing customs, they should meet the New Sunrise and iceworks 23 team who will provide transfers to the Hilton Tokyo Hotel. ",
                "imageUrl": "https://newsunfiles.blob.core.windows.net/iceworks-images/Images/Images/home/airplane-arrival-transfer.png"
            }
        ],
        "2": [
            {
                "title": "Morning",
                "text": "Breakfast will be served from 6:30 AM at the Marble Lounge in the hotel lobby. The Business Session will start at 8:30 AM and will take place in the Kiku Ballroom (Level 4) at the Hilton Tokyo. ",
                "imageUrl": "https://newsunfiles.blob.core.windows.net/iceworks-images/Images/Images/home/Untitled%20design%20(15).png"
            },
            {
                "title": "Afternoon",
                "text": "The Supplier Engagement Session will be held concurrently with the Business Session at the Hilton Tokyo’s Kiku Ballroom and Ballroom Foyer (Level 4). It will begin at 8::00 AM and continue throughout the business session.",
                "imageUrl": "https://newsunfiles.blob.core.windows.net/iceworks-images/Images/Images/home/Hilton%20Business%20Session%20Ballroom.png"
            },
            {
                "title": "Evening",
                "text": "After a busy day of business, get ready to unwind at this year’s Tuesday Dinner which will take place at Ce La Vi in Tokyo. The event will start at 7:00 PM, with transfers departing from the hotel at 6:30 PM. ",
                "imageUrl": "https://newsunfiles.blob.core.windows.net/iceworks-images/Images/Images/home/Ce%20La%20Vie.png"
            }
        ],
        "3": [
            {
                "title": "Morning",
                "text": "Breakfast will be served from 6:30 AM at the Marble Lounge in the hotel lobby. Afterwar, we will begin the Walking Tour of Shinjuku, where you can explore the Japanese market in a small group. ",
                "imageUrl": "https://newsunfiles.blob.core.windows.net/iceworks-images/Images/Images/home/Untitled%20design%20(15).png"
            },
            {
                "title": "Afternoon",
                "text": "The walking tour will occupy most of the morning, including a lunch stop for each group. Lunch service will commence from 12:00 PM. Until then, enjoy exploring one of Tokyo’s most beloved districts.",
                "imageUrl": "https://newsunfiles.blob.core.windows.net/iceworks-images/Images/Images/home/5.png"
            },
            {
                "title": "Evening",
                "text": "On the Wednesday evening, you have the opportunity to explore the city with your friends or family. If you are new to the program and would like some assistance for this free night, please seek out the iceworks 23 events team or a New Sunrise representative. ",
                "imageUrl": "https://newsunfiles.blob.core.windows.net/iceworks-images/Images/Images/home/8.png"
            }
        ],
        "4": [
            {
                "title": "Morning",
                "text": "Breakfast will be served from 6:30 AM at the Marble Lounge in the hotel lobby. New Sunrise and the iceworks 23 events team have arranged some fantastic optional tours to part take in for the day. To learn more about what’s available, please click on the following link: ",
                "imageUrl": "https://newsunfiles.blob.core.windows.net/iceworks-images/Images/Images/home/Untitled%20design%20(15).png"
            },
            {
                "title": "Afternoon",
                "text": "All optional tours will be planned to return to the hotel by 3 PM, allowing plenty of time for you to prepare for the annual Snowball Gala Dinner. The dinner will be hosted offsite and all attendees must be in the hotel lobby by 5:30PM to be transferred to the Gala Dinner location. ",
                "imageUrl": "https://newsunfiles.blob.core.windows.net/iceworks-images/Images/Images/home/6.png"
            },
            {
                "title": "Evening",
                "text": "The Snowball Gala Dinner will take place at the stunning Hotel Gajoen, where you will be immersed in an evening of chic décor with Japanese traditional aesthetics. The venue embodies Japanese omotenashi hospitality. After the Gala Dinner, the celebrations will continue at Zero Tokyo, giving you a true glimpse into Tokyo’s nightlife.",
                "imageUrl": "https://newsunfiles.blob.core.windows.net/iceworks-images/Images/Images/home/Hotel-Gajoen-Tokyo-1.png"
            }
        ],
        "5": [
            {
                "title": "Morning",
                "text": "At Leisure",
                "imageUrl": "https://newsunfiles.blob.core.windows.net/iceworks-images/Images/Images/home/Untitled%20design%20(16).png"
            },
            {
                "title": "Afternoon",
                "text": "At Leisure",
                "imageUrl": "https://newsunfiles.blob.core.windows.net/iceworks-images/Images/Images/home/4.png"
            },
            {
                "title": "Evening",
                "text": "Transfers and all Flight Departures",
                "imageUrl": "https://newsunfiles.blob.core.windows.net/iceworks-images/Images/Images/home/airplane-arrival-transfer.png"
            }
        ]
    }
}


const Home = () => {

    const [smilesMessage, setSmilesMessage] = useState('');
    
    let total = 7950;

    const handleCalculatorSubmit = (e) => {
        e.preventDefault();

        let regex = /^\d+$/;

        if (regex.test(e.target[0].value)) {

            let existingSmiles = e.target[0].value / 1200000;

            let result = total - (existingSmiles * total);

            if (result <= 0) {
                setSmilesMessage(`CONGRATULATIONS! You've earned a fully funded trip to iceworks 24!`);
            }
            else if (result === total) {
                setSmilesMessage(`As you have not particiapted in the incentive period you have not earned any Smiles for the upcoming trip. Your outstanding amount is $${total}`);
            }
            else {
                setSmilesMessage(`CONGRATULATIONS! You've earned a partially funded trip to iceworks 24! Your outstanding amount is $${result.toFixed(2)}`);
            }


        } else {
            setSmilesMessage('');
        }

    }

    
    return (
        <>
            <header className="hero position-relative mb-5" style={{ backgroundImage: 'url("https://newsunfiles.blob.core.windows.net/iceworks-24-images/London_Hero.avif")'}}>
                <div className="hero__overlay">
                    <Container style={{height:'100%'}} >
                        <Fade triggerOnce>
                            
                            <Row className="hero__title-group hero_container">

                                <Col lg={6} className="hero_logoContainer">
                                    <img src="https://newsunfiles.blob.core.windows.net/iceworks-24-images/Iceworks_Logo.png" alt="Iceworks Logo" className="header-img" />
                                    <p class="hero_subtitle">2nd to 6th September 2024</p>
                                </Col>

                                <Col lg={6} className="clock-container">
                                    
                                    <div className="ClockText">Iceworks 24 Countdown</div>
                                    <br />
                                    
                                    <EventCountdown value={"2024-09-02T23:59:59"} />
                                        
                                </Col>
                            </Row>
                            <Row>
                                <Dropdown className="row justify-content-center px-0 ">
                                    <Dropdown.Toggle size="lg" className="main-dropdown" >
                                        Register Now
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="row justify-content-center dropdown-content">
                                        <Stack direction="horizontal" className="justify-content-center" gap={3}>
                                            <Button className="dropdown-button"
                                                target='_blank'
                                                href="https://bxagency.eventsair.com/iceworks-24/members">
                                                Members
                                            </Button>
                                            <Button className="dropdown-button"
                                                target='_blank'
                                                href="https://bxagency.eventsair.com/iceworks-24/suppliers">
                                                Suppliers
                                            </Button>
                                        </Stack>
                                    </Dropdown.Menu>
                                 </Dropdown>
                            </Row>
                        </Fade>
                    </Container>
                </div>
            </header>

            <Container className="about-section mb-5">
                <Fade triggerOnce>
                    <Row className="align-items-center">
                        <Col lg={6}>
                            <h3 className="display-5 text-bold">About Iceworks</h3>
                            <p>
                                Iceworks is an annual conference which brings together the best independent retailers in Australia for a week of networking, insights, and workshops. We invite the top retailers in Australia to this exclusive conference to learn and share.
                            </p>
                            <p>Previous Iceworks locations have included Tokyo, Fiji, Hong Kong, Hanoi, Los Angeles, Noumena, Dallas, San Francisco, Hawaii and Vietnam.'
                            </p>
                        </Col>
                        <Col lg={6}>
                        <div className="image-wrapper">
                                <img className="rounded" src="https://newsunfiles.blob.core.windows.net/iceworks-24-images/Tokyo100p-min.jpg"/>
                        </div>
                        </Col>
                    </Row>
                </Fade>
            </Container>

            <Container fluid className="smiles-section py-5 mb-5  d-flex align-items-center">
                <Container>
                <Fade triggerOnce>
                     <Row>
                        <Col>
                            <h3 className="display-5 text-bold">Check your eligibility</h3>
                            <p>Don't miss out on this adventure of a lifetime. Join the New Sunrise Family, industry professionals and suppliers, to find out what's changing in the industry and how you can grow your business while having a whole lot of fun.</p>

                            <form onSubmit={handleCalculatorSubmit} className="smiles-section__calculator d-flex align-items-center">
                                <Form.Control className="me-3 pb-2" placeholder="Enter your smiles"/>
                                    <Button variant="primary" size="lg" type="submit">Submit</Button>
                            </form>
                            <h4 className="mt-3">{smilesMessage}</h4>
                        </Col>
                    </Row>
                </Fade>
                </Container>
            </Container>

            <Container className="hotel-section mb-5">
               <Fade triggerOnce>
                    <Row className="align-items-center">
                        <Col lg={6}>
                            <h3 className="display-5 text-bold">Hotel</h3>
                            <p>
                            Located in central London, the Hilton London Metropole offers four themed bars and restaurants, a gym, and an executive lounge. Conveniently located near major attractions like Hyde Park and Oxford Street, it offers easy access to explore all that London has to offer.
                            </p>
                        <Link to="/about/hotel"><Button variant="dark" className="mb-2">Read more</Button></Link> 
                        </Col>
                        <Col lg={6}>
                        <div className="image-wrapper">
                                <img className="rounded" alt="London Hilton" src="https://newsunfiles.blob.core.windows.net/iceworks-24-images/Hilton_Hotel.webp"/>
                        </div>
                        </Col>
                    </Row>
               </Fade>
            </Container>

            <Container fluid className= "py-5 d-flex flex-column align-items-center agenda_container">
                <h3 className="display-5 text-bold">Agenda</h3>
                <div className="table_container">
                    <table className="styled-table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Monday</th>
                                <th>Tuesday</th>
                                <th>Wednesday</th>
                                <th>Thursday</th>
                                <th>Friday</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Morning</td>
                                <td rowspan="2">Delegate Arrivals</td>
                                <td>Business Session</td>
                                <td rowspan="2">Study Tour</td>
                                <td rowspan="2">Optional Tour</td>
                                <td rowspan="3">Delegate Departures</td>
                            </tr>
                            <tr>
                                <td>Afternoon</td>
                                <td>Supplier Engagement Session</td>
                            </tr>
                            <tr>
                                <td>Evening</td>
                                <td>Welcome Function</td>
                                <td>Social Dinner</td>
                                <td>Evening at Leisure</td>
                                <td>Snowball Gala Dinner
                                    <br />&
                                    <br />After Party
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Container>
            {/*<Agenda agendaObject={agendaObject}/> */} 
        </>
    )
}


export default Home;