import { Button, Card, Col, Container, Row, Modal } from "react-bootstrap";

const items = {
    a: {
        URL: 'https://newsunfiles.blob.core.windows.net/iceworks-images/Images/Images/stays/Osaka.jpg',
        title: 'OSAKA',
        subtitle: '(2 Day Walking Tour from Osaka)',
        summary: `<ul>
                            <li>World Heritage <b>Mt Koya - one of Japan's most sacred sites ever</b></li>
                            <li>2-day trip from Osaka</li>
                            <li>Experienced guide with a strong knowledge about Koyasan. (1 day only) </li>
                            <li>Stay 1 night at shukubo</li>
                        </ul>`,
        cost: ' <b>Costs are based on YEN until booked - Rates from:  ¥72,220 per person (approx. AUD$802.75) </b>',
        content: ` <b ><u>The Experience</u></b><br />
                            Mount Koya is the center of Shingon Buddhism, founded by Kobo Daishi in Japan in 805. The mountain is home to a small temple town that developed around the sect's headquarters and Kobo Daishi's mausoleum. Over one hundred temples, including Kongobuji and Okunoin, have been built in Koyasan since Kobo Daishi began construction on the original Garan temple complex in 826. Koyasan is also a popular place for an overnight stay at a temple lodging where visitors can experience a monk's lifestyle, including vegetarian monk's cuisine and morning prayers. Additionally, it is the start and end point of the Shikoku 88 Temple Pilgrimage.`
    },
    b: {
        URL: 'https://newsunfiles.blob.core.windows.net/iceworks-images/Images/Images/stays/aerial-view-tokyo-cityscape-with-fuji-mountain-japan.jpg',
        title: 'TOKYO',
        subtitle: '3-Day Express Kyoto & Nara Tour from Tokyo (No Round-trip Shuttle) (Green Car OR Ordinary Car Shinkansen)',
        summary: `<u>The Highlight</u>
                        <ul>
                            <li>Shinkansen ticket included (Green Car or Ordinary Car)</li>
                            <li>Lunch included</li>
                            <li>First day visit Nara</li>
                            <li>Second day visit Kyoto</li>
                            <li>Third day is free time</li>
                        </ul>`,
        cost: '<b>Green car Shinkansen + Twin room from: &nbsp;&nbsp; ¥ 98,670 per person (approx. AUD$1096.72) *<br/>                  Ordinary Car + Twin room from:&nbsp;&nbsp; ¥ 83, 720 per person(approx.AUD$930.55) </b >    <br />Price mentioned are for one person - twin room - no breakfast.<br /> <br />    <u>Additional fee:</u> <br />                                    Single supplement:&nbsp;&nbsp;&nbsp;&nbsp; ¥51, 370 pax / night(approx.AUD$58.00) <br />    Breakfast:&nbsp;&nbsp;&nbsp;&nbsp; ¥5280.00JPY per person per day(approx.AUD$60.00) <br /> <br />',
        content: ` <b ><u>The Experience</u></b><br />
                            Ride the Shinkansen, Japan's famous bullet train, to Kyoto and experience the ancient capital's 1,200 years of rich history.<br/>Round-trip transportation via Shinkansen, bus tours accompanied by an English Guide Interpreter, and hotel accommodation.<br/>The meeting location for this tour: Tourist Information Centre Tokyo near Tokyo Station.<br/>Drop-off location (return) customers may choose from Tokyo Station, Shinagawa Station, and Shin-Yokohama Station.<br/>`
    },
    c: {
        URL: 'https://newsunfiles.blob.core.windows.net/iceworks-images/Images/Images/stays/Mt%20Fuiji%20and%20Hakone.jpg',
        title: 'MT FUJI & HAKONE 2-DAY',
        subtitle: '',
        summary: `<u>ACCOMMODATION TYPES:</u> <br />
                        <br />
                            <b>Western Style room (Yumoto Fujiya Hotel)</b>
                            <ul>
                                <li>Adults from AUD $720.30 per person | Twin Share </li>
                            </ul>
                            <b>Japanese/Western Style rooms (Hotel Okada) </b>
                            <ul>
                                <li>Adults from AUD$ 636.00 per person | Twin Share </li>
                            </ul>
                            <b>Japanese style room (Kakone Kowakien Ten-Yu) </b>
                            <ul>
                                <li>Adults from $740.0 per person | Twin Share </li>
                            </ul>`,
        cost: '',
        content: ` <b ><u>Day 1 | Tokyo</u></b><br />
                            Depart Tokyo by coach for Mt Fuji and drive halfway up the mountain to Mt Fuji's 5th station (Altitude 2305m), after lunch take a cruise on Lake Ashi then ride an aerial cableway up and don Mt Komagatake for sweeping views of Hakone National Park. Overnight in Hakone and enjoy hot spring facilities at the accommodation.         <br/><br/>                                
                                <b ><u>Day 2 | Hakone</u></b><br />  Enjoy sightseeing at Hakone on your own before making your way back to Tokyo.`
    },
    d: {
        URL: 'https://newsunfiles.blob.core.windows.net/iceworks-images/Images/Images/stays/Snow-monkey.jpg',
        title: 'SNOW MONKEYS & SHUKUBOS EXPERIENCE',
        subtitle: '',
        summary: ` <u>ACCOMMODATION TYPES:</u> <br /><br />
                        2 nights' accommodation <br />
                        <b>Meals</b><br />
                        2x Breakfast, 1x Dinner<br /><br />
                        <b>Adults from:&nbsp;&nbsp;AUD$2049.00 per person based on Twin Share Land only <br />
                        Adults from:&nbsp;&nbsp;AUD$1399.00 per person base on Single Supplement Land only</b> <br /><br />`,
        cost: '',
        content: `<b ><u>Day 1 | Tokyo Togakushi Zenkoji Temple (L,D)</u></b><br />
                            Take the bullet train in the morning to Nagano, you will be met on arrival, transferred to the 7th Century Zenkoji temple by local bus, immerse yourself in the history and culture, tonight you will stay in a traditional Temple Lodge and enjoy a Monk’s dinner.


                            <br /><br />

                        <b ><u>Day 2 | Zenkoji Obuse Yadanaka Onsen (B, D) </u></b><br />  
                        After breakfast your guide will assist you in transferring to Obuse by local Train.  Obuse is a picturesque town known for its woodblock artwork, it is often referred to as Little Kyoto.  After exploring, your guide will help you board the local train to Yudanaka. You will be met on arrival and transferred to your hotel.
                        <br /><br />
                        <b ><u>Day 3 | Snow Mickey Park Yudanaka Nagano Tokyo (B)</u></b><br />  
                        Today will see the famous Snow Monkeys, transfer from your hotel to the Snow Monkey Park (Please note that entry to the park is not included you will pay on arrival).  After spending time wandering through the park and watching these adorable creatures, take the local train to Nagano then a Bullet train to Tokyo. `
    },

};

function Display({ name }) {
    const info = items[name];
    return (
        <Container className="my-4">
            <Row className="mb-4">
                <div className="tour-image mb-3 rounded" style={{ backgroundImage: `url(${info.URL})` }}>
                    <div className="tour-image__overlay text-white d-flex align-items-center justify-content-center">
                        <div className="text-center">
                            <h3 className="display-5">{info.title}</h3>
                            <h4>{info.subtitle}</h4>
                        </div>
                    </div>
                </div>
                <p dangerouslySetInnerHTML={{ __html: info.summary }}></p>
                <p dangerouslySetInnerHTML={{ __html: info.cost }}></p>
                <p dangerouslySetInnerHTML={{ __html: info.content }}></p>
            </Row>
        </Container>
    )
}

const BespokeStays = () => {
    return (
        <>
            <Container className="mt-4">
                <h2 className="text-center text-bold display-4 py-5">Bespoke Stays</h2>
                <img src="https://newsunfiles.blob.core.windows.net/iceworks-24-images/Tuscany__Italy.webp" alt="Tuscany"></img>
                <p className="pt-4">Embark on a journey tailored exclusively to you with our bespoke travel service. From the moment you dream of your European adventure, we're here to craft an experience that exceeds every expectation. Whether it's an intimate getaway or a family retreat, our expert travel advisors meticulously curate every detail to reflect your unique preferences and desires. Indulge in luxury accommodations, immersive cultural experiences, and exclusive access to hidden gems around the globe. With our personalized touch and unwavering commitment to excellence, every journey becomes a masterpiece of unforgettable moments. Unlock the world of bespoke travel and let us turn your dreams into reality.</p>

                <Row className="bg-primary ps-5 align-items-end">
                    <Col className="mt-3" lg={5}>
                        <p className="px-4 py-4 my-0 fs-5">
                            Reach out to one of our experienced travel managers to book your Bespoke Stay adventure now!
                        </p>
                        <Button variant="secondary" className="ms-5" size="lg" href="mailto: info@iceworks24.com.au?subject=Iceworks 24 Stays Enquiry">Enquire Now!</Button>
                    </Col>
                    <Col className="px-5 d-flex justify-content-end" lg={7}>
                        <img height={'150px'} src="https://newsunfiles.blob.core.windows.net/iceworks-24-images/Travel_Footer.png" alt="Banner" />
                    </Col>
                </Row>
            </Container>

        </>
    )
}


export default BespokeStays;