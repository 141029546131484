import React, { useEffect, useState }  from 'react';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import Layout from './components/layout/layout';
import About from './pages/about/about.component';
import Flights from './pages/about/flights.component';
import Hotel from './pages/about/hotel.component';
import ImportantInformation from './pages/about/important-information.component';

import MiniStays from './pages/stays/mini-stays.component';
import BespokeStays from './pages/stays/bespoke-stays.component';
import ExtendedStays from './pages/stays/extended-stays.component';

import FAQ from './pages/faq/faq-component';
import Home from './pages/home/home.component';
import OptionalTours from './pages/optional-tours/option-tours.component';





const App = () => {

  const location = useLocation();

  useEffect(() => {
    window.scrollTo({top: 0, behavior: "instant"});
  }, [location])

    return (
        
        <Routes>
            
        <Route path='/' element={<Layout/>}>
                <Route index element={<Home />} />


          <Route path='about'>
              <Route index element={<About/>}/>
              <Route path='hotel' element={<Hotel/>}/>
              <Route path='flights' element={<Flights/>}/>
              <Route path='important-information' element={<ImportantInformation/>}/>
                </Route>


                <Route path='optional-tours' element={<OptionalTours />} />
                


          <Route path='stays'>
                    <Route path='mini-stays' element={<MiniStays />} />
                    <Route path='bespoke-stays' element={<BespokeStays />} />
                <Route path='extended-stays' element={<ExtendedStays/>} />
                </Route>


          <Route path='faq' element={<FAQ/>}/>
        </Route>
        </Routes>

         
      
    );
}

export default App;